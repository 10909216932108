<template>
	<section class="section">
		<div class="container">
			<h1>404</h1>
		</div>
	</section>
</template>

<script>
	export default {
		metaInfo() {
			return {
				title: `Forever Diamond Team - 404`,
			};
		},
	};
</script>

<style scoped lang="scss"></style>
